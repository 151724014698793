import React, { useEffect, useState } from "react"
import ReactPaginate from "react-paginate"
import Items from "./items"

const PaginatedItems = ({ itemsPerPage, items }) => {
  const [currentItems, setCurrentItems] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage
    setCurrentItems(items.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(items.length / itemsPerPage))
  }, [items, itemOffset, itemsPerPage])

  const handlePageClick = event => {
    const newOffset = (event.selected * itemsPerPage) % items.length
    setItemOffset(newOffset)
  }

  return (
    <>
      <div className="grid grid-cols-2 gap-18px">
        <Items currentItems={currentItems} />
      </div>
      <ReactPaginate
        breakLabel="..."
        nextLabel="Next »"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        className="flex justify-center w-full my-5 items-center gap-x-1"
        pageLinkClassName="text-lg"
        previousLinkClassName="text-lg text-link hover:bg-darker-gray hover:opacity-50 hover:text-white py-2 px-1"
        nextLinkClassName="text-lg text-link hover:bg-darker-gray hover:opacity-50 hover:text-white py-2 px-1"
        pageClassName="py-1 px-2 text-link hover:bg-darker-gray hover:opacity-50 hover:text-white"
        activeLinkClassName="text-white"
        activeClassName="bg-darker-gray"
        disabledClassName="hidden"
        pageCount={pageCount}
        previousLabel="« Previous"
        renderOnZeroPageCount={null}
      />
    </>
  )
}

export default PaginatedItems
