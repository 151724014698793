import React from "react"

const Items = ({ currentItems }) => {
  return (
    <>
      {currentItems &&
        currentItems.map((item, index) => (
          <div className="p-18px bg-white" key={index}>
            <p className="text-lg text-primary">{item}</p>
          </div>
        ))}
    </>
  )
}

export default Items
