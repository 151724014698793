import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import BackgroundImageWrapper from "../components/backgroundImage"
import Layout from "../components/layout"
import PaginatedItems from "../components/paginatedItems"
import Seo from "../components/seo"

const Recensies = () => {
  const result = useStaticQuery(graphql`
    query getReviews {
      allMarkdownRemark(filter: { frontmatter: { title: { eq: "Reviews" } } }) {
        edges {
          node {
            frontmatter {
              reviews
              professional {
                description
                job
                name
              }
            }
          }
        }
      }
    }
  `)

  const { reviews, professional } =
    result.allMarkdownRemark.edges[0].node.frontmatter

  return (
    <Layout>
      <Seo title="Recensies" />
      <BackgroundImageWrapper hero="hero5" position="bottom" height="219px">
        <div className="absolute inset-0 bg-gray opacity-60" />
        <div className="px-4 py-75px flex justify-center flex-col text-center relative">
          <h1 className="font-bold text-white mb-15px text-3xl">Recensies</h1>
        </div>
      </BackgroundImageWrapper>

      <section className="py-75px px-5 bg-secondary text-center">
        <div className="max-w-700px mx-auto">
          <h2 className="text-primary text-27px mb-15px">
            Recensies van klanten
          </h2>
          <PaginatedItems itemsPerPage={10} items={reviews} />
        </div>
      </section>

      <section className="py-75px px-5">
        <div className="max-w-700px mx-auto">
          <h2 className="text-primary text-27px mb-15px text-center">
            Recensies van professionals
          </h2>
          <div className="flex flex-col gap-y-30px">
            {professional &&
              professional.map((item, index) => (
                <div className="p-5 bg-secondary" key={index}>
                  <p className="text-lg text-primary mb-15px">
                    {item.description}
                  </p>
                  <span className="block text-primary text-xl font-bold">
                    {item.name}
                  </span>
                  <span className="block text-secondary text-15px italic">
                    {item.job}
                  </span>
                </div>
              ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Recensies
